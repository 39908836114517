import React, { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CssBaseline,
  Divider,
  Typography,
  Paper,
  Container,
  Card,
  CardContent,
  CardActions,
  Button, TextField,
  Grid,Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
// import {
//   Dashboard as DashboardIcon,
//   AccountBalance as PaymentIcon,
//   AccountBalanceWallet as TranHisIcon,
//   AccountBalanceWallet as AccountBalanceWalletIcon,
//   Forum as AllComplaintIcon,
//   Help as HelpIcon,
//   Report as ReportIcon,
//   ChevronLeft as ChevronLeftIcon,
//   Menu as MenuIcon,
//   AccountCircle as LogoutIcon,
//   LegendToggleRounded
// } from "@mui/icons-material";
import AuthApi4 from "../services/authApi";
import TvIcon from '@mui/icons-material/Tv';
import HouseIcon from '@mui/icons-material/House';
// import Sidebar from "../sidebar/Sidebar";
// import Navbar from "../sidebar/Navbar";
import Navbar from "../sidebar/SideNavbar";
import Footer from "../sidebar/Footer";

export default function Dashboard() {
  const navigate = useNavigate();
  const [lcowallet, setlcowallet] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0);
  const [errors, setErrors] = useState({});

  const [totalBoxes, setTotalBoxes] = useState(0);
  const [activeBoxes, setActiveBoxes] = useState(0);
  const [inactiveBoxes, setInactiveBoxes] = useState(0);

  useEffect(() => {
    getlcowallet();
    stbinfo();
    
  }, [navigate]);

  const handleClickOpenWallet = () => {
    setOpenWallet(true);
    // setWalletAmount(lcowallet);
  };

  const handleWalletClose = () => {
    setOpenWallet(false);
  };

  const handleWalletAmountChange = (event) => {
    // Ensure only numbers are entered
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setWalletAmount(value);
      setErrors({});
    }
  };


  const isLoggedIn = localStorage.getItem("isLoggedIn");
  if (isLoggedIn === null) {
    navigate("/login");
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setWalletAmount(event.target.value);

      event.preventDefault();    }
  };


  const stbinfo = async(event) =>{
    let data = {
      records: [
        {
          phone: localStorage.getItem("phoneNumber"),
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID:localStorage.getItem("payment_cust_num"),
          type:"stbInfo"
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        console.log(response.data.stbdetails[0]);
        let total_value=response.data.stbdetails[0].L_TOTAL_BOXES;
        setTotalBoxes(total_value===null? 0:total_value);
        let active_value=response.data.stbdetails[0].L_ACTIVE_BOXES;
        setActiveBoxes(active_value===null? 0:active_value);
        let inactive_value=response.data.stbdetails[0].L_INACTIVE_BOXES;
        setInactiveBoxes(inactive_value===null? 0:inactive_value);
      })
      .catch((e) => {
        console.log("STB INFO Error");

        console.log(e);
      });
  }
  


  const getlcowallet = async () => {
    let data =  {
      records: [
      {
        CUST_ID:localStorage.getItem("payment_cust_num"),
        type:"wallet"
          },
        ],
      }
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        let billTotal = 0;
        if (response.data.stbdetails[0].bill_total >= 0){
          setlcowallet(response.data.stbdetails[0].bill_total);
        } else {
          billTotal=-(response.data.stbdetails[0].bill_total);
          setlcowallet(billTotal); 
        }
      })
      .catch((e) => {
        console.log("Wallet Error");

        console.log(e);
      });
  };

  const handleAddWalletAmount= async() => {
    console.log(walletAmount);
    if (walletAmount === "") {
      setErrors({
        walletAmount: <font color="red">Amount cannot be empty.</font>,
      });
      return;
    }else{
      let data =  {
        records: [
        {
          CUST_ID:localStorage.getItem("payment_cust_num"),
          l_amount:walletAmount,
          type:"update"
            },
          ],
        }
      console.log(data);
      await AuthApi4.stbinfo(data)
        .then((response) => {
          console.log(response);
          if (response.data.stbdetails[0].p_out_mssg_flg=== "S"){
            alert("Added Successfully");
            window.location.reload();          
          } else {
            alert("Error"); 
          }
        })
        .catch((e) => {
          console.log("Wallet Api Error");
  
          console.log(e);
        });
    }
  };

  return (
    <div style={{ display: "flex", background: "#F5F5F5"}}>
      <CssBaseline />
      <Navbar />
      {/* <Sidebar /> */}
      <main style={{ flexGrow: 1, padding: '0px', minHeight: '100vh'}}>
        {/* <Toolbar /> */}
      <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 64px)', 
            margin: 'auto', 
            maxWidth: 'lg', 
            height: '100vh',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // marginTop: 0
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: 2,
                    textAlign: 'center',
                    borderRadius: 4,
                    boxShadow: 6,
                    width: '100%',
                    // margin: 0,
                    marginBottom: 5
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    <HouseIcon sx={{ marginRight: 1, color: '#ff8c00' }} />
                    {localStorage.getItem("payment_cust_name")}
                  </Typography>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 3,
                        width: '100%',
                      }}
                    >
                      <Typography variant="body1">
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                          Mobile Number:
                        </Typography>{" "}
                        {localStorage.getItem("phoneNumber")}
                      </Typography>
                      <Typography variant="body1">
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                          Wallet Balance:
                        </Typography>{" "}
                        {lcowallet}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpenWallet}
                          sx={{ marginLeft: 2 }}
                        >
                          Add
                      </Button>
                    </Typography>
                    <Dialog 
                        open={openWallet} 
                        onClose={handleWalletClose} 
                        aria-labelledby="wallet-dialog-title"
                        sx={{
                          '& .MuiDialog-paper': { 
                            minWidth: 300, // Set a minimum width for the dialog
                            maxWidth: '80%', // Set a maximum width for responsiveness
                            margin: 'auto' // Ensure the dialog is centered
                          }
                        }}
                      >
                         <DialogTitle 
                          id="wallet-dialog-title"
                          sx={{
                            textAlign: 'center', // Center the title
                            color: '#ff8c00' // Set the color to #ff8c00
                          }}
                        >
                          Add Amount
                        </DialogTitle>
                        <DialogContent>
                            {/* Label and numeric input field */}
                            <Typography sx={{ marginBottom: 2 }}>
                              Enter Amount:
                            </Typography>
                            <TextField
                              label="Amount"
                              variant="outlined"
                              fullWidth
                              value={walletAmount}
                              id="walletAmount"
                              onKeyDown={handleKeyDown}
                              error={!!errors.walletAmount}
                              helperText={errors.walletAmount}
                              onChange={handleWalletAmountChange}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict to numeric input
                            />
                          </DialogContent>
                          <DialogActions
                            sx={{ 
                              justifyContent: 'center' // Center align buttons
                            }}
                          >
                            <Button 
                              onClick={handleAddWalletAmount} 
                              sx={{ 
                                backgroundColor: '#ff8c00', // Set button color
                                color: '#fff', // Set button text color to white
                                '&:hover': {
                                  backgroundColor: '#e07b00' // Optional: change color on hover
                                }
                              }}
                            >
                              Submit
                            </Button>
                          </DialogActions>
                      </Dialog>
                  </Box>
                  </Grid>
                </Paper>
              </Grid>
            <Grid container spacing={3}>
            {/* Total Boxes Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#1E90FF' }} /> {/* Icon for Total Boxes */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Total Boxes
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {totalBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>

            {/* Active Count Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#32CD32' }} /> {/* Icon for Active Count */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Active
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {activeBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>

            {/* Suspended Count Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 2, paddingRight: 18 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#FF4500' }} /> {/* Icon for Suspended */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Suspended
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {inactiveBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          </Grid>
          </Box>
        </Container>
        <Footer />
      </main>
    </div>
  );
}
