import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem
} from "@mui/material";
import AuthApi4 from "../services/authApi";
import Navbar from "../sidebar/SideNavbar";
import setTopBox from "../images/stb_new.jpeg";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function BulkRenewal() {
  const [stbData, setStbData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedSTBs, setSelectedSTBs] = useState([]); // Array to store selected STB_IDs
  const [selectedOption, setSelectedOption] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    stbinfoNew();
  }, []);

  const stbinfoNew = async () => {
    setLoading(true);
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          type: "bulkSubMngmnt"
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        setStbData(response.data.stbdetails);
        setLoading(false);
        const initialCheckedState = {};
        response.data.stbdetails.forEach((item, index) => {
          initialCheckedState[index] = false;
        });
        setCheckedItems(initialCheckedState);
      })
      .catch((e) => {
        setLoading(false);
        console.log("STB INFO Error");
        console.log(e);
      });
  };

  const handleCheckboxChange = (index, stbId) => {
    setCheckedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));

    // Add or remove STB_ID from the selectedSTBs array
    setSelectedSTBs(prevSelected => {
      if (prevSelected.includes(stbId)) {
        // Remove if it's already selected
        return prevSelected.filter(id => id !== stbId);
      } else {
        // Add if it's not selected
        return [...prevSelected, stbId];
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    setSelectedOption(option); // Optionally store the selected option
  };

  const handleSelectedOption = async(event) => {
    if (selectedSTBs.length === 0) {
      // Display the alert if no STBs are selected
      Swal.fire({
        title: "No STBs Selected",
        text: "Please select at least one STB to proceed.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      return; // Exit the function early if no STBs are selected
    }
  
    const stbIdsArray = selectedSTBs.map(item => ({ stb_id: item }));
  
    if (selectedOption === 'Renew') {
      console.log(stbIdsArray);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to Renew?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
          popup: 'small-swal-popup', 
        },
      });
  
      if (result.isConfirmed) {
        setLoading(true); 
        let data = {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          stb_ids: stbIdsArray,
          type: "renewBulk"
        };
  
        console.log(data);
  
        await AuthApi4.bulkSTBRenew(data)
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (response.data[0].p_out_mssg_flg === 'S') {
            Swal.fire({
              icon: 'success',
              title: 'Renewed Successfully',
              // text: 'The total amount must be greater than zero to renew the pack.',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
            return; 
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Failed to Renew',
              text: response.data[0].p_out_mssg || 'An error occurred while bulk renew.',
            });
            return; 
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("Bulk Renew API Error");
          console.log(e);
        });
      }
    } else if (selectedOption === 'Add channel') {
      console.log(stbIdsArray);
      navigate('/bulkAddChannel', { state: { stbId: stbIdsArray } });
    } else if (selectedOption === 'Modify and Renew') {
      console.log(stbIdsArray);
      navigate('/bulkModify', { state: { stbId: stbIdsArray } });
    } else {
      console.log('Unknown option');
    }
  };  

  
  const selectAll = () => {
    const newCheckedState = {};
    const allSTBs = stbData.map(item => item.STB_ID);
    stbData.forEach((_, index) => {
      newCheckedState[index] = true;
    });
    setCheckedItems(newCheckedState);
    setSelectedSTBs(allSTBs); // Select all STB_IDs
  };

  const unselectAll = () => {
    const newCheckedState = {};
    stbData.forEach((_, index) => {
      newCheckedState[index] = false;
    });
    setCheckedItems(newCheckedState);
    setSelectedSTBs([]); // Unselect all STB_IDs
  };

  const checkedCount = Object.values(checkedItems).filter(Boolean).length;

  return (
    <div style={{ display: "flex", background: "#F5F5F5" }}>
      <CssBaseline />
      <Navbar />
      <main style={{ flexGrow: 1 }}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            minHeight: '100vh',
            padding: 10
          }}
        >
          <Box display="flex" alignItems="center">
            <h3 style={{ marginRight: '20px' }}>Bulk Renewal</h3>
            <Button variant="contained" color="primary" onClick={selectAll} style={{ marginRight: '10px' }}>
              Select All
            </Button>
            <Button variant="contained" color="warning" onClick={unselectAll}>
              Unselect All
            </Button>
            <div style={{ position: 'absolute', right: '40px' }}>
              <h5>Selected Boxes: {checkedCount}</h5>
            </div>
          </Box>
          <Grid container spacing={1} style={{ marginTop: '2px' }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                <CircularProgress />
              </Box>
            ) : Array.isArray(stbData) && stbData.length > 0 ? (
              stbData.map((item, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: checkedItems[index] ? '#d3ffd3' : '#f5f5f5',
                    borderRadius: 4,
                    boxShadow: 6,
                    transition: 'background-color 0.3s ease'
                  }}>
                    
                    <Grid container style={{ width: '100%', position: 'relative' }}> {/* Add position: relative here */}
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px',paddingTop: '8px', margin: '0' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ fontSize: '1rem', margin: '0' }}>
                            <strong>ID: </strong>
                            <strong style={{ color: '#ff8c00', fontWeight: 'bold', fontSize: '1rem' }}>
                              {item.STBMODEL}
                            </strong>
                          </p>
                        </div>
                      </Grid>
                      
                      {/* Move checkbox to the top-right corner */}
                      <div style={{ position: 'absolute', right: '2px' }}> {/* Adjust position relative to parent */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedItems[index] || false}
                              onChange={() => handleCheckboxChange(index, item.STB_ID)}
                            />
                          }
                        />
                      </div>
                    </Grid>

                    <Grid container style={{ width: '100%', minHeight: '160px' }}>
                      <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-start', padding: '6px', margin: '0' }}>
                        <img
                          src={setTopBox}
                          alt="Set-Top Box"
                          style={{
                            width: '85%',
                            height: 'auto',
                            maxHeight: '100px',
                            objectFit: 'contain',
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0', margin: '0' }}>
                        <CardContent style={{ flexGrow: 1, padding: '0', marginLeft: '0' }}>
                          <div>
                            <p style={{ fontSize: '0.65rem' }}>STB Number: <strong>{item.STB_NUMBER}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>VC Number: <strong>{item.VC_NUMBER}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Pack Amount: <strong>{item.sub_total}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Status: <strong><span style={{ color: item.STATUS === "Active" ? "green" : "red" }}>{item.STATUS}</span></strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Expiry Date: <strong>{moment(item.PRE_END_DATE).utc().format('DD-MM-YYYY')}</strong></p>
                          </div>
                        </CardContent>
                        {/* <CardActions style={{ justifyContent: 'flex-start', paddingBottom: 10 }}>
                        </CardActions> */}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
            ) : (
              <p>No data available</p>
            )}
          </Grid>

          <Grid container spacing={1} style={{ marginTop: '20px' }}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="success"
                onClick={handleClick}
                disabled={selectedSTBs.length === 0}
              >
                Bulk Operations
              </Button>

              {/* Dropdown menu that appears when button is clicked */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
              >
                <MenuItem onClick={() => handleClose('Renew')}>Renew</MenuItem>
                <MenuItem onClick={() => handleClose('Add channel')}>Add channel</MenuItem>
                <MenuItem onClick={() => handleClose('Modify and Renew')}>Modify and Renew</MenuItem>
              </Menu>
            </Grid>

            {selectedOption && (
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" style={{ alignSelf: 'center' }}
                onClick={handleSelectedOption}>
                  {selectedOption}
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
