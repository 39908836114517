import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  CssBaseline,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../sidebar/SideNavbar";
import * as XLSX from "xlsx";
import AuthApi4 from "../services/authApi";
import moment from 'moment';

export default function RenewalReport() {
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered table data
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // For universal search

  useEffect(() => {
    renewalInfo();
  }, [navigate]);

  const renewalInfo = async (event) => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          phone: localStorage.getItem("phoneNumber"),
          type: "renewInfo",
        },
      ],
    };
    console.log(data);
    await AuthApi4.renewReport(data)
      .then((response) => {
        console.log(response.data.re);
        setReportData(response.data.re);
        setFilteredData(response.data.re); // Initialize filteredData with the full reportData
      })
      .catch((e) => {
        console.log("Renewal Report Api Error");
        console.log(e);
      });
  };

  // Excel export function
  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Renewal Report");
    XLSX.writeFile(workbook, "RenewalReport.xlsx");
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchClick = async (event) => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          phone: localStorage.getItem("phoneNumber"),
          from_date: startDate,
          to_date: endDate,
          type: "Filter",
        },
      ],
    };
    console.log(data);
    await AuthApi4.renewReport(data)
      .then((response) => {
        console.log(response.data.re);
        setReportData(response.data.re || []);
        setFilteredData(response.data.re || []);
      })
      .catch((e) => {
        console.log("Renewal Report Api Error");
        console.log(e);
      });
  };

  // Universal Search Functionality
  const handleSearchTermChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    
    const filtered = reportData.filter((row) =>
      (row.stb_number ? row.stb_number.toLowerCase() : '').includes(searchValue) ||
      (row.prod_id ? row.prod_id.toLowerCase() : '').includes(searchValue) ||
      (row.status ? row.status.toLowerCase() : '').includes(searchValue) ||
      (row.response ? row.response.toLowerCase() : '').includes(searchValue) ||
      (row.user_price ? row.user_price.toString().toLowerCase() : '').includes(searchValue) ||
      (row.date ? moment(row.date).format('DD-MM-YYYY').toLowerCase() : '').includes(searchValue) ||
      (row.updated_time ? moment(row.updated_time).format('DD-MM-YYYY hh:mm:ss').toLowerCase() : '').includes(searchValue)
    );
    
    setFilteredData(filtered);
  };

  return (
    <div style={{ display: "flex", background: "#F5F5F5" }}>
      <CssBaseline />
      <Navbar />
      <main style={{ flexGrow: 1, padding: "5px", minHeight: "100vh" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 10 }}>
          <Typography variant="h5">Renewal Report</Typography>
        </Box>

        {/* Search Bar */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
            marginBottom: 2,
            gap: 2, // Adds space between items
          }}
        >
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={handleStartDateChange}
            sx={{ maxWidth: 200 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            value={endDate}
            onChange={handleEndDateChange}
            sx={{ maxWidth: 200 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" onClick={handleSearchClick}>
            Get Report
          </Button>
           <Button variant="contained" color="primary" onClick={handleExportToExcel}>
            Export
          </Button>
        </Box>

        {/* Universal Search */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: 2,
            marginRight: 4
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchTermChange}
            sx={{ maxWidth: 300 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            margin: "auto",
            maxWidth: "lg",
            height: "auto",
          }}
        >
          <Grid container spacing={2} sx={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {['STB NUMBER', 'PROD ID', 'STATUS', 'RESPONSE', 'AMOUNT', 'RENEWED DATE', 'UPDATED DATE'].map((text) => (
                          <TableCell
                            key={text}
                            sx={{
                              backgroundColor: '#ff8c00',
                              color: '#ffffff', // Optional: Set text color to white for better contrast
                              borderColor: '#ff8c00',
                            }}
                          >
                            {text}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(filteredData) && filteredData.length > 0 ? (
                        filteredData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.stb_number}</TableCell>
                              <TableCell>{row.prod_id}</TableCell>
                              <TableCell>{row.status}</TableCell>
                              <TableCell>{row.response}</TableCell>
                              <TableCell>{row.user_price}</TableCell>
                              <TableCell>{moment(row.date).format('DD-MM-YYYY')}</TableCell>
                              <TableCell>{moment(row.updated_time).format('DD-MM-YYYY hh:mm:ss')}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
