import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Card,
  CardContent,
  CardActions,
  Menu,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress, // Import a loading spinner from Material-UI
} from "@mui/material";
import AuthApi4 from "../services/authApi";
import Navbar from "../sidebar/SideNavbar";
import setTopBox from "../images/stb_new.jpeg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SubscriptionManagement() {
  const [menuInfo, setMenuInfo] = useState({ anchorEl: null, stbNumber: null, stbId: null, index: null });
  const [stbData, setStbData] = useState([]);
  const [stbNumber, setStbNumber] = useState('');
  const [loading, setLoading] = useState(true);  // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    stbinfoNew();
  }, []); // Fetch data when the component is mounted

  const stbinfoNew = async () => {
    setLoading(true); // Set loading to true when starting to fetch
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          type: "subMngmnt"
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        setStbData(response.data.stbdetails);
        setLoading(false); // Set loading to false once data is fetched
        console.log(response.data.stbdetails);
      })
      .catch((e) => {
        setLoading(false); // Stop loading even on error
        console.log("STB INFO Error");
        console.log(e);
      });
  };

  const handleFilterChange = async (event) => {
    const newStbNumber = event.target.value;
    setStbNumber(newStbNumber);
    setLoading(true); // Start loading for filtering as well
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          type: "FILTER",
          stb_number: newStbNumber
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        setStbData(response.data.stbdetails);
        setLoading(false); // Stop loading after filtering
        console.log(response.data.stbdetails);
      })
      .catch((e) => {
        setLoading(false); // Stop loading on error
        console.log("STB INFO Error");
        console.log(e);
      });
  };

  const handleClickMenu = (event, stbNumber, stbId, index) => {
    setMenuInfo({ anchorEl: event.currentTarget, stbNumber, stbId, index });
  };

  const handleClickModify = () => {
    const stbIdsArray = Array.isArray(menuInfo.stbId)
      ? menuInfo.stbId.map(item => item) 
      : [menuInfo.stbId];  

    navigate('/bulkModify', { state: { stbId: stbIdsArray } });
  };

  const handleClickRenew = () => {
    navigate('/renew', { state: { stbNumber: menuInfo.stbNumber } });
  };

  const handleClickAddChannel = () => {
    navigate('/addChannel', { state: { stbId: menuInfo.stbId } });
  };

  const handleClickRetrack = () => {
    navigate('/retrack', { state: { stbId: menuInfo.stbId } });
  };

  const handleClickCancel= async(event) => {
    let stbId= menuInfo.stbId;
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Cancel Box?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        popup: 'small-swal-popup', 
      },
    });

    if (result.isConfirmed) {
      setLoading(true); 
      let data = {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          stb_id: stbId
      };

      console.log(data);

      await AuthApi4.cancelSTB(data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data.p_out_mssg_flg === 'S') {
          Swal.fire({
            icon: 'success',
            title: 'Box Cancelled Successfullly',
            // text: 'The total amount must be greater than zero to renew the pack.',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
          return; 
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Cancellation Failed',
            text: response.data.p_out_mssg || 'An error occurred while Cancelling Box.',
          });
          return; 
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Single Cancellation API Error");
        console.log(e);
      });
    }
  };

  const handleCloseMenu = () => {
    setMenuInfo({ anchorEl: null, stbNumber: null, stbId: null, index: null });
  };

  return (
    <div style={{ display: "flex", background: "#F5F5F5" }}>
      <CssBaseline />
      <Navbar />
      <main style={{ flexGrow: 1 }}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            minHeight: '100vh',
            padding: 10
          }}
        >
          <Box>
            <TextField
              sx={{ width: '50%' }}
              label="Search by STB Number"
              variant="outlined"
              value={stbNumber}
              onChange={handleFilterChange}
            />
          </Box>
          <Grid container spacing={1} style={{ marginTop: '2px' }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                <CircularProgress /> {/* Display spinner while loading */}
              </Box>
            ) : Array.isArray(stbData) && stbData.length > 0 ? (
              stbData.map((item, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5',
                    borderRadius: 4,
                    boxShadow: 6,
                  }}>
                    <Grid container style={{ width: '100%', position: 'relative' }}>
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px',paddingTop: '8px', margin: '0' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ fontSize: '1rem', margin: '0' }}>
                            <strong>ID: </strong>
                            <strong style={{ color: '#ff8c00', fontWeight: 'bold', fontSize: '1rem' }}>
                              {item.STBMODEL}
                            </strong>
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ width: '100%', minHeight: '160px', margin: '0', gap: '0px' }}>
                      <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-start', padding: '6px', margin: '0' }}>
                        <img
                          src={setTopBox}
                          alt="Set-Top Box"
                          style={{
                            width: '85%',
                            height: 'auto',
                            maxHeight: '100px',
                            objectFit: 'contain',
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0', margin: '0' }}>
                        <CardContent style={{ flexGrow: 1, padding: '0', marginLeft: '0' }}>
                          <div>
                            <p style={{ fontSize: '0.65rem' }}>STB Number: <strong>{item.STB_NUMBER}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>VC Number: <strong>{item.VC_NUMBER}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Pack Amount: <strong>{item.sub_total}</strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Status: <strong><span style={{ color: item.STATUS === "Active" ? "green" : "red" }}>{item.STATUS}</span></strong></p>
                            <p style={{ fontSize: '0.65rem' }}>Expiry Date: <strong>{moment(item.PRE_END_DATE).utc().format('DD-MM-YYYY')}</strong></p>
                          </div>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'flex-end', paddingBottom: 10 }}>
                          <Button
                            onClick={(event) => handleClickMenu(event, item.STB_NUMBER, item.STB_ID, index)}
                            sx={{
                              backgroundColor: '#ff8c00',
                              color: '#fff',
                              fontSize: '0.7rem',
                              padding: '4px 8px',
                              '&:hover': {
                                backgroundColor: '#ffad42',
                              },
                            }}
                          >
                            Action
                            <ArrowDropDownIcon sx={{ ml: 1 }} />
                          </Button>
                            <Menu
                            anchorEl={menuInfo.index === index ? menuInfo.anchorEl : null}
                            open={menuInfo.index === index}
                            onClose={handleCloseMenu}
                          >
                            {item.STATUS === 'Active' ? (
                          [
  <MenuItem key="renew" onClick={handleClickRenew}>Renew</MenuItem>,
  <MenuItem key="add-channel" onClick={handleClickAddChannel}>Add Channel</MenuItem>,
  <MenuItem key="retrack" onClick={handleClickRetrack}>Retrack</MenuItem>,
  localStorage.getItem("cancel_status") === "y" && (
    <MenuItem key="cancel" onClick={handleClickCancel}>Cancel</MenuItem>
  )
].filter(Boolean) 
                            ) : (
                              <MenuItem onClick={handleClickModify}>Modify</MenuItem>
                            )}
                          </Menu>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
            ) : (
              <p>No data available</p>
            )}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
