import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  CssBaseline,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../sidebar/SideNavbar";
import AuthApi4 from "../services/authApi";
import moment from 'moment';

export default function WalletHistory() {
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    setFilteredData(
      reportData.filter((row) => 
        (row.tran_id?.toString() || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.No_of_days ? moment(row.No_of_days).format('YYYY-MM-DD').toLowerCase() : '').includes(searchTerm) ||
        (row.No_of_STB?.toString() || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.user_name?.toString() || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.deposit?.toString() || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.balance?.toString() || "").toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, reportData]);

  const getItems = async () => {
    try {
      let data = {
        records: [
          {
            sub_oid: parseInt(localStorage.getItem("payment_cust_num")),
            type: "REACT_CUSTOMER"
          },
        ],
      };
      await AuthApi4.renewReport(data).then((response) => {
        let plans = response.data.re;
        let planslist = plans
          .sort((a, b) => a.No_of_days - b.No_of_days)
          .map((plan) => ({
            tran_id: plan.tran_id,
            No_of_days: plan.insertion_time,
            No_of_STB: plan.tran_details,
            user_name: plan.deduction,
            deposit: plan.deposit,
            balance: plan.balance,
          }));

        setReportData(planslist);
        setFilteredData(planslist);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  return (
    <div style={{ display: "flex", background: "#F5F5F5" }}>
      <CssBaseline />
      <Navbar />
      <main style={{ flexGrow: 1, padding: "5px", minHeight: "100vh" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 10 }}>
          <Typography variant="h5">Wallet History</Typography>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchTermChange}
            sx={{ marginRight: 4, maxWidth: 300 }}
          />
        </Box>

        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            margin: "auto",
            maxWidth: "lg",
            height: "auto",
          }}
        >
          <Grid container spacing={2} sx={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {['STB NUMBER','DATE', 'TRANSACTION DETAILS', 'DEDUCTION', 'DEPOSIT', 'WALLET BALANCE'].map((text) => (
                          <TableCell
                            key={text}
                            sx={{
                              backgroundColor: '#ff8c00',
                              color: '#ffffff',
                              borderColor: '#ff8c00',
                            }}
                          >
                            {text}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(filteredData) && filteredData.length > 0 ? (
                        filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.tran_id}</TableCell>
                              <TableCell>{moment.utc(row.No_of_days).format("YYYY-MM-DD")}</TableCell>
                              <TableCell>{row.No_of_STB}</TableCell>
                              <TableCell>{row.user_name}</TableCell>
                              <TableCell>{row.deposit}</TableCell>
                              <TableCell>{row.balance}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
