import React, { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  Badge,
  Paper,
  Container,
  Avatar,
  Card,
  CardContent,
  CardActions,
  Button, Collapse, TextField,
  Grid,Dialog, DialogTitle, DialogContent, DialogActions, Icon
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  AccountBalance as PaymentIcon,
  AccountBalanceWallet as TranHisIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Forum as AllComplaintIcon,
  Help as HelpIcon,
  Report as ReportIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  AccountCircle as LogoutIcon
} from "@mui/icons-material";
// import PersonIcon from '@mui/icons-material/Person';
import logo from "./../images/mpayicon2.png";
import profile from "../images/profile.png";
import AuthApi4 from "../services/authApi";
import TvIcon from '@mui/icons-material/Tv';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HouseIcon from '@mui/icons-material/House';
// import ReportIcon from '@mui/icons-material/Report';

const drawerWidth = 250;


const useStyles = styled((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    overflowX: 'hidden'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(6), // Adjusted padding
    marginTop: theme.spacing(6), // Ensure space for the AppBar
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // maxWidth: '1200px', // Adjust max width if needed
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 220,
  },
}));

export default function Nxtdashboard() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [lcowallet, setlcowallet] = useState("");
  const theme = useTheme();

  const [openDialog, setDialog] = useState(false);
  const [openList, setOpenList] = useState(false);

  const [openWallet, setOpenWallet] = useState(false);
  const [walletAmount, setWalletAmount] = useState("");
  const [errors, setErrors] = useState({});

  const [totalBoxes, setTotalBoxes] = useState(0);
  const [activeBoxes, setActiveBoxes] = useState(0);
  const [inactiveBoxes, setInactiveBoxes] = useState(0);

  useEffect(() => {
    getlcowallet();
    stbinfo();
    
  }, []);

  const handleClickOpenWallet = () => {
    setOpenWallet(true);
    setWalletAmount(lcowallet);
  };

  const handleWalletClose = () => {
    setOpenWallet(false);
  };

  const handleWalletAmountChange = (event) => {
    // Ensure only numbers are entered
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setWalletAmount(value);
      setErrors({});
    }
  };


  const handleClickList = () => {
    setOpenList(!openList);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleProfileClose = () => {
    setDialog(false);
    
  };

  const handleDialogClose = () => {

      navigate("/login");
      localStorage.clear();
    
  };

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  if (isLoggedIn === null) {
    navigate("/login");
  }

  const one = () => navigate("/newDashboard");
  const two = () => navigate("/subscriptionM");
  const wallet = () => navigate("/NxtPay");

  const three = () => navigate("/NXT_Renewal");
  const three1 = () => navigate("/RENEW");
  const three2 = () => navigate("/CANCEL");
  

  const handleDrawerOpen = () => {
    console.log("Dashboard");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    console.log("DashboardClose");
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setWalletAmount(event.target.value);

      event.preventDefault();    }
  };


  const stbinfo = async(event) =>{
    let data = {
      records: [
        {
          phone: localStorage.getItem("phoneNumber"),
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID:localStorage.getItem("payment_cust_num"),
          type:"stbInfo"
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        console.log(response.data.stbdetails[0]);
        let total_value=response.data.stbdetails[0].L_TOTAL_BOXES;
        setTotalBoxes(total_value===null? 0:total_value);
        let active_value=response.data.stbdetails[0].L_ACTIVE_BOXES;
        setActiveBoxes(active_value===null? 0:active_value);
        let inactive_value=response.data.stbdetails[0].L_INACTIVE_BOXES;
        setInactiveBoxes(inactive_value===null? 0:inactive_value);
      })
      .catch((e) => {
        console.log("STB INFO Error");

        console.log(e);
      });
  }
  


  const getlcowallet = async () => {
    let data =  {
      records: [
      {
        CUST_ID:localStorage.getItem("payment_cust_num"),
        type:"wallet"
          },
        ],
      }
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        let billTotal = 0;
        if (response.data.stbdetails[0].bill_total >= 0){
          setlcowallet(response.data.stbdetails[0].bill_total);
        } else {
          billTotal=-(response.data.stbdetails[0].bill_total);
          setlcowallet(billTotal); 
        }
      })
      .catch((e) => {
        console.log("Wallet Error");

        console.log(e);
      });
  };

  const handleAddWalletAmount= async() => {
    console.log(walletAmount);
    if (walletAmount === "") {
      setErrors({
        walletAmount: <font color="red">Amount cannot be empty.</font>,
      });
      return;
    }else{
      let data =  {
        records: [
        {
          CUST_ID:localStorage.getItem("payment_cust_num"),
          l_amount:walletAmount,
          type:"update"
            },
          ],
        }
      console.log(data);
      await AuthApi4.stbinfo(data)
        .then((response) => {
          console.log(response);
          if (response.data.stbdetails[0].p_out_mssg_flg=== "S"){
            alert("Added Successfully");
            window.location.reload();          
          } else {
            alert("Error"); 
          }
        })
        .catch((e) => {
          console.log("Wallet Api Error");
  
          console.log(e);
        });
    }
  };

const currentYear = new Date().getFullYear();
  return (
    <div style={{ display: "flex", background: "#F5F5F5"}}>
      <CssBaseline />
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: '#ff8c00',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) => theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: '36px',
                ...(open ? { display: 'none' }:{}),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {localStorage.getItem("payment_cust_name")}
            </Typography>
          </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton color="inherit">
                  <Badge badgeContent={0} color="secondary">
                    <AccountBalanceWalletIcon /> Wallet Balance: {lcowallet}
                  </Badge>
                </IconButton>
                <IconButton color="inherit" sx={{ marginLeft: 2 }} onClick={handleDialogOpen}>
                  <Avatar alt="Profile" src="/path-to-profile-image.jpg" /> {/* Replace with the actual path to the profile image */}
                </IconButton>
                <Dialog open={openDialog} onClose={handleProfileClose} PaperProps={{ sx: { position: 'absolute', top: '60px', right: '10px' } }}>
                  <DialogTitle sx={{ color: '#ff8c00', }}>Profile Info</DialogTitle>
                  <DialogContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left' }}>
                    <Avatar
                      src={profile} // Replace with the path to your image file
                      alt="Profile Icon"
                      style={{ width: 60, height: 60, marginBottom: 16 }} // Adjust size and margin as needed
                    />
                    <Box sx={{ textAlign: 'left', width: '100%' }}>
                      <Typography variant="body1" gutterBottom>
                        Network Name: <strong>{localStorage.getItem("payment_cust_name")}</strong>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Mobile Number: <strong>{localStorage.getItem("phoneNumber")}</strong>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Password: <strong>{localStorage.getItem("Password")}</strong>
                      </Typography>
                    </Box>
                  </Box>
                </DialogContent>
                  <DialogActions>
                    <Button variant="contained" sx={{ backgroundColor: '#ff8c00', '&:hover': { backgroundColor: '#ff8c50' }, color: '#fff', }} onClick={handleDialogClose}>
                      Log Out
                    </Button>
                  </DialogActions>
            </Dialog>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: (theme) => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            ...(open ? {} : {
              overflowX: 'hidden',
              transition: (theme) => theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: (theme) => theme.spacing(7),
              [theme.breakpoints.up('sm')]: {
                width: (theme) => theme.spacing(9),
              },
            }),
          }
        }}
        open={open}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "0 8px",
        }}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListSubheader inset>
            <img src={logo} alt="logo" width="110" height="45" />
          </ListSubheader>
          <hr />
          <ListItem onClick={one}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem onClick={two}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Subscription Management" />
          </ListItem>
          <ListItem onClick={three}>
            <ListItemIcon>
              <AllComplaintIcon />
            </ListItemIcon>
            <ListItemText primary="Bulk Renewal" />
          </ListItem>
          {localStorage.getItem("cancellation_enabled") === "Y" && (
            <ListItem onClick={three2}>
              <ListItemIcon>
                <AllComplaintIcon />
              </ListItemIcon>
              <ListItemText primary="Bulk Cancel" />
            </ListItem>
          )}
          <ListItem onClick={three1}>
            <ListItemIcon>
              <AllComplaintIcon />
            </ListItemIcon>
            <ListItemText primary="Cancel STB Renewal" />
          </ListItem>
          <ListItem onClick={wallet}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Top-up Wallet" />
          </ListItem>
          <ListItem button onClick={handleClickList}>
        <ListItemIcon>
          <TranHisIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      
      {/* Sub-items */}
      <Collapse in={openList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4 }}>
            <ListItemIcon>
              <AllComplaintIcon />
            </ListItemIcon>
            <ListItemText primary="Cancellation Report" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }}>
            <ListItemIcon>
              <AllComplaintIcon />
            </ListItemIcon>
            <ListItemText primary="Cancellation STB Report" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }}>
            <ListItemIcon>
              <AllComplaintIcon />
            </ListItemIcon>
            <ListItemText primary="Wallet History" />
          </ListItem>
          
        </List>
      </Collapse>
          
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate("/helpnsupport")}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Help & Support" />
          </ListItem>
          <ListItem button onClick={() => navigate("/termsncondition")}>
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary="Terms & Condition" />
          </ListItem>
          <ListItem button onClick={() => {
            navigate("/login");
            localStorage.clear();
          }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main style={{ flexGrow: 1, padding: '0px', minHeight: '100vh'}}>
        {/* <Toolbar /> */}
      <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 64px)', // Ensure full height minus the AppBar
            margin: 'auto', // Center the container horizontally
            maxWidth: 'lg', // Use Material-UI's predefined maxWidth for responsiveness,
            height: '100vh',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // marginTop: 0
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: 2,
                    textAlign: 'center',
                    borderRadius: 4,
                    boxShadow: 6,
                    width: '100%',
                    // margin: 0,
                    marginBottom: 5
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    <HouseIcon sx={{ marginRight: 1, color: '#ff8c00' }} />
                    {localStorage.getItem("payment_cust_name")}
                  </Typography>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 3,
                        width: '100%',
                      }}
                    >
                      <Typography variant="body1">
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                          Mobile Number:
                        </Typography>{" "}
                        {localStorage.getItem("phoneNumber")}
                      </Typography>
                      <Typography variant="body1">
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                          Wallet Balance:
                        </Typography>{" "}
                        {lcowallet}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpenWallet}
                          sx={{ marginLeft: 2 }}
                        >
                          Add
                      </Button>
                    </Typography>
                    <Dialog 
                        open={openWallet} 
                        onClose={handleWalletClose} 
                        aria-labelledby="wallet-dialog-title"
                        sx={{
                          '& .MuiDialog-paper': { 
                            minWidth: 300, // Set a minimum width for the dialog
                            maxWidth: '80%', // Set a maximum width for responsiveness
                            margin: 'auto' // Ensure the dialog is centered
                          }
                        }}
                      >
                         <DialogTitle 
                          id="wallet-dialog-title"
                          sx={{
                            textAlign: 'center', // Center the title
                            color: '#ff8c00' // Set the color to #ff8c00
                          }}
                        >
                          Add Amount
                        </DialogTitle>
                        <DialogContent>
                            {/* Label and numeric input field */}
                            <Typography sx={{ marginBottom: 2 }}>
                              Enter Amount:
                            </Typography>
                            <TextField
                              label="Amount"
                              variant="outlined"
                              fullWidth
                              value={walletAmount}
                              id="walletAmount"
                              onKeyDown={handleKeyDown}
                              error={!!errors.walletAmount}
                              helperText={errors.walletAmount}
                              onChange={handleWalletAmountChange}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict to numeric input
                            />
                          </DialogContent>
                          <DialogActions
                            sx={{ 
                              justifyContent: 'center' // Center align buttons
                            }}
                          >
                            <Button 
                              onClick={handleAddWalletAmount} 
                              sx={{ 
                                backgroundColor: '#ff8c00', // Set button color
                                color: '#fff', // Set button text color to white
                                '&:hover': {
                                  backgroundColor: '#e07b00' // Optional: change color on hover
                                }
                              }}
                            >
                              Submit
                            </Button>
                          </DialogActions>
                      </Dialog>
                  </Box>
                  </Grid>
                </Paper>
              </Grid>
            <Grid container spacing={3}>
            {/* Total Boxes Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#1E90FF' }} /> {/* Icon for Total Boxes */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Total Boxes
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {totalBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>

            {/* Active Count Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#32CD32' }} /> {/* Icon for Active Count */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Active
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {activeBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>

            {/* Suspended Count Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              >
                <CardContent sx={{ flexGrow: 1, paddingLeft: 2, paddingRight: 18 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TvIcon sx={{ marginRight: 1, color: '#FF4500' }} /> {/* Icon for Suspended */}
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      Suspended
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 2, fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {inactiveBoxes}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          </Grid>
          </Box>
        </Container>
        <Box
          component="footer"
          sx={{
            py: 1,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary" align="center">
            <h4>
                Copyright &copy; {currentYear} Mobiezy.com. All rights reserved.
            </h4>
          </Typography>
        </Box>
        {/* <Copyright /> */}
      </main>
    </div>
  );
}
