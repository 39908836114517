import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography
} from "@mui/material";
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  AccountCircle as LogoutIcon,
  AccountBalance as PaymentIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Forum as AllComplaintIcon,
  Menu as MenuIcon,
  Report as ReportIcon,
  AccountBalanceWallet as TranHisIcon
} from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import logo from "./../images/mpayicon2.png";
import profile from "../images/profile.png";
import AuthApi4 from "../services/authApi";

const drawerWidth = 250;

export default function SidebarAndNavbar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);  // Sidebar open state
  const [openList, setOpenList] = useState(false);  // Reports collapse state
  const [openRenewList, setOpenRenewList] = useState(false); 
  const [lcowallet, setlcowallet] = useState("");  // Wallet balance state
  const [openDialog, setDialog] = useState(false);  // Profile dialog open state

  useEffect(() => {
    getlcowallet();
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  if (isLoggedIn === null) {
    navigate("/login");
  }

  const getlcowallet = async () => {
    let data = {
      records: [
        {
          CUST_ID: localStorage.getItem("payment_cust_num"),
          type: "wallet",
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        let billTotal = response.data.stbdetails[0].bill_total;
        setlcowallet(billTotal >= 0 ? billTotal : -billTotal);
      })
      .catch((e) => {
        console.log("Wallet Error", e);
      });
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleClickList = () => {
    setOpenList(!openList);
  };

  const handleClickRenewList = () => {
    setOpenRenewList(!openRenewList);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.clear();
  };

  const one = () => navigate("/Dashboard");
  const two = () => navigate("/subscriptionM");
  const three = () => navigate("/bulkRenewal");
  const three1 = () => navigate("/RENEW");
  const three2 = () => navigate("/bulkCancel");
  const four = () => navigate("/bulkInActiveRenewal");

  return (
    <>
      {/* Navbar */}
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: '#ff8c00',
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ marginRight: '36px', ...(open ? { display: 'none' }: {}) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            {localStorage.getItem("payment_cust_name")}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <AccountBalanceWalletIcon /> Wallet Balance: {lcowallet}
            </Badge>
          </IconButton>
          <IconButton color="inherit" sx={{ marginLeft: 2 }} onClick={handleDialogOpen}>
            <Avatar alt="Profile" src={profile} />
          </IconButton>

          {/* Profile Dialog */}
          <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{ sx: { position: 'absolute', top: '60px', right: '10px' } }}>
            <DialogTitle sx={{ color: '#ff8c00' }}>Profile Info</DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar src={profile} alt="Profile Icon" style={{ width: 60, height: 60, marginBottom: 16 }} />
                <Box sx={{ textAlign: 'left', width: '100%' }}>
                  <Typography variant="body1">Network Name: <strong>{localStorage.getItem("payment_cust_name")}</strong></Typography>
                  <Typography variant="body1">Mobile Number: <strong>{localStorage.getItem("phoneNumber")}</strong></Typography>
                  <Typography variant="body1">Password: <strong>{localStorage.getItem("Password")}</strong></Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" sx={{ backgroundColor: '#ff8c00', color: '#fff' }} onClick={handleLogout}>
                Log Out
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            ...(open ? {} : {
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
              [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
              },
            }),
          },
        }}
        open={open}
      >
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "0 8px" }}>
          <IconButton onClick={handleDrawerToggle}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListSubheader inset>
            <img src={logo} alt="logo" width="110" height="45" />
          </ListSubheader>
          <Divider />
          <ListItem button onClick={one}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={two}>
            <ListItemIcon><PaymentIcon /></ListItemIcon>
            <ListItemText primary="Subscription Management" />
          </ListItem>
          <ListItem button onClick={handleClickRenewList}>
            <ListItemIcon><RefreshIcon /></ListItemIcon>
            <ListItemText primary="Bulk Renewal" />
            {openRenewList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openRenewList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }} onClick={three}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="Active Boxes" /></ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={four}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="InActive Boxes" /></ListItem>
            </List>
          </Collapse>
          {localStorage.getItem("cancellation_enabled") === "Y" && (
            <ListItem button onClick={three2}>
              <ListItemIcon><CancelIcon /></ListItemIcon>
              <ListItemText primary="Bulk Cancel" />
            </ListItem>
          )}
          {/* <ListItem button onClick={three1}>
            <ListItemIcon><CancelIcon /></ListItemIcon>
            <ListItemText primary="Cancel STB Renewal" />
          </ListItem> */}
          <ListItem button onClick={handleClickList}>
            <ListItemIcon><TranHisIcon /></ListItemIcon>
            <ListItemText primary="Reports" />
            {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* <ListItem button sx={{ pl: 4 }}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="Cancellation Report" /></ListItem> */}
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate("/cancellationReport")}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="Cancellation STB Report" /></ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate("/walletHistory")}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="Wallet History" /></ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => navigate("/renewalReport")}><ListItemIcon><AllComplaintIcon /></ListItemIcon><ListItemText primary="Renewal Report" /></ListItem>
            </List>
          </Collapse>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate("/termsandconditions")}>
            <ListItemIcon><ReportIcon /></ListItemIcon>
            <ListItemText primary="Terms & Conditions" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
