import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material'; // For consistent baseline styling
import Login from './login&password/login';
import Dashboard from  './dashboard/Dashboard';
import NewDashboard from  './dashboard/NDashboard';
import SubscriptionManagement from  './subscriptionManagement/SubscriptionM';
import RenewSTB from  './subscriptionManagement/RenewSTB';
import AddChannel from  './subscriptionManagement/AddChannel';
import TermsAndConditions from  './support/PrivacyPolicy';
import RenewalReport from  './reports/RenewalReport';
import WalletHistory from  './reports/WalletHistory';
import BulkRenewal from  './bulk/BulkRenewal';
import BulkInActiveRenewal from  './bulk/BulkInActiveRenew';
import BulkAddChannel from  './bulk/BulkAddChannel';
import BulkModify from  './bulk/BulkModify';
import BulkCancel from  './bulk/BulkCancel';
import CancellationSTBReport from  './reports/CancellationSTBReport';


function App() {
  return (
    <Router>
      <CssBaseline /> {/* Provides consistent baseline styles */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/newDashboard" element={<NewDashboard />} />
        <Route path="/subscriptionM" element={<SubscriptionManagement />} />
        {/* <Route path="/renew/:stbNumber" element={<RenewSTB />} /> */}
        <Route path="/renew" element={<RenewSTB />} />
        <Route path="/addChannel" element={<AddChannel />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/cancellationReport" element={<CancellationSTBReport />} />
        <Route path="/renewalReport" element={<RenewalReport />} />
        <Route path="/walletHistory" element={<WalletHistory />} />
        <Route path="/bulkRenewal" element={<BulkRenewal />} />
        <Route path="/bulkInActiveRenewal" element={<BulkInActiveRenewal />} />
        <Route path="/bulkAddChannel" element={<BulkAddChannel />} />
        <Route path="/bulkModify" element={<BulkModify />} />
        <Route path="/bulkCancel" element={<BulkCancel />} />
      </Routes>
    </Router>
  );
}

export default App;
