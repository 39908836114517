import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box, Container, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import AuthApi4 from "../services/authApi";
import logo from "../images/mpayicon2.png";
import logo1 from "../images/heart1.png";


function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setPhoneNumber(event.target.value);

      event.preventDefault();    }
  };

  const handleKeyDownPassword = (event) => {
    if (event.key === "Enter") {
      setPassword(event.target.value);

      event.preventDefault();    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const signIn = async (event) => {
    // eslint-disable-next-line no-unused-vars
    //event.preventDefault()
    if (phoneNumber === "") {
      setErrors({
        phoneNumber: <font color="red">Phone number can't be empty.</font>,
      });
      return;
    }else if(password === ""){
      setErrors({
        password: <font color="red">Password can't be empty.</font>,
      });
      return;
    }
    let userData = {
      records: [
        {
          phone: phoneNumber,
          password:password
        },
      ],
    };
    console.log(JSON.stringify(userData));
    await AuthApi4.login(userData)
      .then((response) => {
        console.log(response);
        if (response.data.stbdetails[0].p_out_mssg_flg === "S") {
          // toast.error("Please Wait...", {
          //   position: toast.POSITION.BOTTOM_CENTER,
          // });
          console.log("authentication is successful");
          // if (response.data.stbdetails[0].L_MSO_ID === 4) {
            localStorage.setItem("phoneNumber", phoneNumber);
            localStorage.setItem("custid", response.data.stbdetails[0].L_sms_cust_id);
            localStorage.setItem("lcocode", response.data.stbdetails[0].L_Merchant_Salt);
            localStorage.setItem("sms_cust_id", response.data.stbdetails[0].L_sms_cust_id);
            localStorage.setItem("Password", response.data.stbdetails[0].L_password);
            localStorage.setItem("ownerId", response.data.stbdetails[0].L_OPERATOR_ID);
            localStorage.setItem("network_name", response.data.stbdetails[0].L_OP_NETWORK);
            localStorage.setItem("op_phone", response.data.stbdetails[0].L_OP_PHONE);
			localStorage.setItem("cancel_status", response.data.stbdetails[0].app_update_flag);
            localStorage.setItem("op_email", response.data.stbdetails[0].L_OP_EMAIL);
            // localStorage.setItem("comp_name", response.data.stbdetails[0].L_cw_name);
            // localStorage.getItem("network_name");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem(
              "payment_cust_num",
              response.data.stbdetails[0].L_cust_num_cw
            );
            localStorage.setItem(
              "cancellation_enabled",
              response.data.stbdetails[0].L_cancellation_enabled
            );
            localStorage.setItem("payment_cust_name", response.data.stbdetails[0].L_cw_name);
            localStorage.setItem("BULK_IMPORT_FLAG", "Y");
            navigate("/Dashboard");
         
        } else {
          alert(
            "Invalid Mobile Number & Password."
          );
          // setErrors({
          //   phoneNumber: <font color="red">Invalid Mobile Number & Password</font>,
          // });
          // isValid = false;
          // return;
        }
      })
      .catch((e) => {
        // history.push('/unauthorized');
        alert("Internet issue");
        console.log(e);
      });
  };

 
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
          bgcolor: 'rgba(255, 255, 255, 0.8)', // White background with transparency
          p: 4, // Padding around the form
          borderRadius: 2, // Rounded corners
          boxShadow: 3, // Optional shadow for better visibility
        }}
      >
        <img src={logo} alt="logo" width="100" height="50" />
        <Typography variant="h5" component="h1" gutterBottom>
          Mobi<span style={{ color: '#ff8c00' }}>Online</span> Payment
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Enter Phone Number"
            type="tel"
            id="phoneNumber"
            onChange={handlePhoneNumber}
            onKeyDown={handleKeyDown}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            inputProps={{ pattern: "[1-9]{1}[0-9]{9}", maxLength: 10 }}
            sx={{ borderRadius: 2 }}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Enter Password"
            type="tel"
            id="password"
            onChange={handlePassword}
            onKeyDown={handleKeyDownPassword}
            error={!!errors.password}
            helperText={errors.password}
            inputProps={{ pattern: "[1-9]{1}[0-9]{9}", maxLength: 4 }}
            sx={{ borderRadius: 2 }}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="warning"
            onClick={signIn}
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            Made with <img src={logo1} alt="logo" width="20" height="20" /> in India!
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;
