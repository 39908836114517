import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  CssBaseline,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../sidebar/SideNavbar";
import AuthApi4 from "../services/authApi";
import moment from 'moment';

export default function CancellationSTBReport() {
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtering
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Universal search term

  useEffect(() => {
    renewalInfo();
  }, []);

  const renewalInfo = async (event) => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          cust_num: localStorage.getItem("payment_cust_num"),
          type: "cancelSTB"
        },
      ],
    };
    console.log(data);
    await AuthApi4.renewReport(data)
      .then((response) => {
        console.log(response.data.re);
        setReportData(response.data.re);
        setFilteredData(response.data.re); // Initialize with full data
      })
      .catch((e) => {
        console.log("Renewal Report Api Error");
        console.log(e);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // const handleSTBChange = (event) => {
  //   setSTBNumber(event.target.value);
  // };

  const handleSearchClick = async (event) => {
    let firstDate = moment(startDate).utc().format("YYYY-MM-DD hh:mm:ss");
    let lastDate = moment(endDate).utc().format("YYYY-MM-DD hh:mm:ss");
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          cust_num: localStorage.getItem("payment_cust_num"),
          from_date: startDate,
          to_date: endDate,
          type: "cancelFilter"
        },
      ],
    };
    console.log(data);
    await AuthApi4.renewReport(data)
      .then((response) => {
        console.log(response.data.re);
        setReportData(response.data.re || []);
        setFilteredData(response.data.re || []); // Update filtered data
      })
      .catch((e) => {
        console.log("Cancellation Report Api Error");
        console.log(e);
      });
  }

  const handleUniversalSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    
    const filtered = reportData.filter(row => 
      (row.stb_number ? row.stb_number.toLowerCase() : '').includes(term) ||
      (row.prod_id ? row.prod_id.toLowerCase() : '').includes(term) ||
      (row.subs_desc ? row.subs_desc.toLowerCase() : '').includes(term) ||
      (row.user_price ? row.user_price.toString().toLowerCase() : '').includes(term)
    );
  
    setFilteredData(filtered); // Update table with filtered data
  };

  return (
    <div style={{ display: "flex", background: "#F5F5F5" }}>
      <CssBaseline />
      <Navbar />
      <main style={{ flexGrow: 1, padding: "5px", minHeight: "100vh" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 10 }}>
          <Typography variant="h5">Cancellation Report</Typography>
        </Box>

        {/* Search Bar */}
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            marginTop: 2, 
            marginBottom: 2, 
            gap: 2 // Adds space between items
          }}
        >
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={handleStartDateChange}
            sx={{ maxWidth: 200 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            value={endDate}
            onChange={handleEndDateChange}
            sx={{ maxWidth: 200 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" onClick={handleSearchClick}>
            Get Report
          </Button>
        </Box>

        {/* Universal Search */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: 2,marginRight: 4 }}>
          <TextField
            label="Search"
            type="text"
            variant="outlined"
            value={searchTerm}
            onChange={handleUniversalSearch}
            sx={{ maxWidth: 300 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            margin: "auto",
            maxWidth: "lg",
            height: "auto",
          }}
        >
          <Grid container spacing={2} sx={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {['STB NUMBER','PROD ID','CHANNEL NAME', 'USER PRICE','DATE'].map((text) => (
                          <TableCell
                            key={text}
                            sx={{
                              backgroundColor: '#ff8c00',
                              color: '#ffffff', // Optional: Set text color to white for better contrast
                              borderColor: '#ff8c00'
                            }}
                          >
                            {text}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(filteredData) && filteredData.length > 0 ? (
                        filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.stb_number}</TableCell>
                              <TableCell>{row.prod_id}</TableCell>
                              <TableCell>{row.subs_desc}</TableCell>
                              <TableCell>{row.user_price}</TableCell>
                              <TableCell>{moment.utc(row.date).utc().format("YYYY-MM-DD hh:mm:ss")}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
