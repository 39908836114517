import React, { useState,useEffect, useCallback } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton, TextField, FormControl, FormGroup, FormControlLabel, Checkbox, Table,TableContainer, TableHead, TableBody, TableRow, TableCell,CircularProgress
} from "@mui/material";
import { ArrowBack as ArrowBackIcon,Search as SearchIcon } from "@mui/icons-material";
import AuthApi4 from "../services/authApi";
// import Navbar from "../sidebar/Navbar";
// import Sidebar from "../sidebar/Sidebar";
import Navbar from "../sidebar/SideNavbar";
import Footer from "../sidebar/Footer";
import Swal from 'sweetalert2';

export default function AddChannel() {
const location = useLocation();
  const { stbId } = location.state || {};
  const [activeSection, setActiveSection] = useState('bouquetChannel');
  const navigate = useNavigate();
  const [section1Items, setSection1Items] = useState([]); 
  const [selectedItems, setSelectedItems] = useState([]); 
  const [section2Items, setSection2Items] = useState([]); 
  // const [selectedBouquetItems, setSelectedBouquetItems] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [planD, setPlanD] = useState([]); 
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    addChannel('bouquetChannel');
  },[]);

  const addChannel = useCallback(async (section) => {
    if(section === 'bouquetChannel') {
      bouquetChannelDetails();
    } else {
      payChannelDetails();
    }
  }, []);

  useEffect(() => {
    addChannel('bouquetChannel');
  }, [addChannel]);

  const bouquetChannelDetails = useCallback(async () => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          stbId: stbId,
          genre: "A",
          type: "addChnl"
        },
      ],
    };
    console.log(data);
    await AuthApi4.addChannels(data)
      .then((response) => {
        console.log(response.data.packDetails);
        setSection2Items(response.data.packDetails);
      })
      .catch((e) => {
        console.log("Add Channel Api Error");
        console.log(e);
      });
  }, [stbId]); // Add stbId because it's used inside
  
  // Memoize payChannelDetails
  const payChannelDetails = useCallback(async () => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID: localStorage.getItem("payment_cust_num"),
          stbId: stbId,
          genre: "C",
          type: "addChnl"
        },
      ],
    };
    console.log(data);
    await AuthApi4.addChannels(data)
      .then((response) => {
        console.log(response);
        setSection1Items(response.data.packDetails);
      })
      .catch((e) => {
        console.log("Add Channel Api Error");
        console.log(e);
      });
  }, [stbId]);
  
  const packTableInfo = useCallback(async () => {
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          subs_id: selectedItems,
          type: "packInfo"
        },
      ],
    };
    console.log(data);
    await AuthApi4.addChannels(data)
      .then((response) => {
        console.log(response.data.packDetails);
        setPlanD(response.data.packDetails);
      })
      .catch((e) => {
        console.log("Add Channel Api Error");
        console.log(e);
      });
  }, [selectedItems]); // It depends on selectedItems, so include it here
  
  useEffect(() => {
    packTableInfo();
  }, [selectedItems, packTableInfo]);


  const handlePayCheckboxChange = useCallback((item) => {
    setSelectedItems(prevState => {
        const isSelected = prevState.includes(item);
        const updatedState = isSelected
            ? prevState.filter(id => id !== item)
            : [...prevState, item];
        return updatedState;
    });
}, []);

  const handleBouquetCheckboxChange = useCallback((item) => {
    setSelectedItems(prevState => {
        const isSelected = prevState.includes(item);
        const updatedState = isSelected
            ? prevState.filter(id => id !== item)
            : [...prevState, item];
        return updatedState;
    });
}, []);

  useEffect(() => {
    packTableInfo();
  }, [selectedItems]);

  const handleBackClick = () => {
    navigate("/subscriptionM");
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
    addChannel(section);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = async(event) => {
    console.log('Search Term:', searchTerm);
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          stbId:stbId,
          genre:activeSection ==='bouquetChannel' ? 'A' : 'C',
          subs_name:searchTerm,
          type:"packFilter"
        },
      ],
    };
    console.log(data);
    await AuthApi4.addChannels(data)
      .then((response) => {
        console.log(response);
        activeSection ==='bouquetChannel' ?setSection2Items(response.data.packDetails):setSection1Items(response.data.packDetails);
      })
      .catch((e) => {
        console.log("Add Channel Api Error");

        console.log(e);
      });
  };

  const handleAddChannelBtn = async(event) =>{
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Add Channels?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        popup: 'small-swal-popup', 
      },
    });

    if (result.isConfirmed) {
      if (selectedItems.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Packs Not Selected',
          text: 'Please select a pack before proceeding.'
        });
        return; 
      }

      setLoading(true); 

      const subsIdsArray = selectedItems.map(item => ({ subs_id: item }));

      let data = {
            operator_id: parseInt(localStorage.getItem("ownerId")),
            CUST_ID: localStorage.getItem("payment_cust_num"),
            stb_id:stbId,
            subs_ids:subsIdsArray,
            type: "addChnls"
      };

      console.log(data);

      await AuthApi4.stbAddChannel(data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data.add_c[0].p_out_mssg_flg === 'S') {
          Swal.fire({
            icon: 'success',
            title: 'Added Successfully',
            // text: 'The total amount must be greater than zero to renew the pack.',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/subscriptionM");
            }
          });
          return; 
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Failed to Add Channel',
            text: response.data.p_out_mssg || 'An error occurred while adding the plan.',
          });
          return; 
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Add channel API Error");
        console.log(e);
      });
    }
  }

  const handleCancelBtn = ()=>{
    window.location.reload();
  }

  return (
    <div style={{ display: "flex", background: "#F5F5F5"}}>
      <CssBaseline />
      <Navbar />
      {/* <Sidebar /> */}
      <main style={{ flexGrow: 1, padding: '5px', minHeight: '100vh'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
          <Typography variant="h5" component="h2">
            Add Channels
          </Typography>
          <IconButton onClick={handleBackClick} 
              sx={{
                marginRight: 2,
                backgroundColor: '#ff8c00',
                fontSize: '0.875rem',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#ffad42',
                },
              }}>
            <ArrowBackIcon sx={{ fontSize: '1rem', marginRight: '4px' }}/>Back
          </IconButton>
        </Box>

        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 64px)', 
            margin: 'auto', 
            maxWidth: 'lg', 
            height: '100vh',
          }}
        >
          <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: activeSection === 'bouquetChannel' ? '#ff8c00' : '#fff',
                    color: activeSection === 'bouquetChannel' ? '#fff' : '#000',
                    '&:hover': {
                      backgroundColor: activeSection === 'bouquetChannel' ? '#ff8c00' : '#f5f5f5',
                    },
                    height: '60%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                  }}
                  onClick={() => handleSectionClick('bouquetChannel')}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                      Bouquet Packs
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: activeSection === 'payChannel' ? '#ff8c00' : '#fff',
                    color: activeSection === 'payChannel' ? '#fff' : '#000',
                    '&:hover': {
                      backgroundColor: activeSection === 'payChannel' ? '#ff8c00' : '#f5f5f5',
                    },
                    height:'60%',
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                  }}
                  onClick={() => handleSectionClick('payChannel')}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                      Pay Channels
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: 1 }}>
            <TextField
                sx={{ flexGrow: 1}}  
                label="Search by Pack Name"
                variant="outlined"
                size="small" 
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <IconButton
                sx={{
                padding: '8px', 
                marginLeft: '8px', 
                cursor: 'pointer',
                '&:hover': {
                      backgroundColor: '#ff8c00',
                    },
                }}
                onClick={handleSearchClick}
            >
                <SearchIcon />
            </IconButton>
            </Box>
            <Box sx={{ marginTop: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    {activeSection === 'payChannel' ? (
                        <Box 
                        sx={{
                            height: '200px',
                            border: '1px solid black',
                            overflowY: 'auto',  
                            paddingRight: '8px' 
                        }}
                        >
                        <FormControl component="fieldset">
                            <FormGroup>
                            {Array.isArray(section1Items) && section1Items.length > 0 ? (
                                section1Items.map((item, index) => (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={selectedItems.includes(item.subs_id)}
                                        onChange={() => handlePayCheckboxChange(item.subs_id)}
                                      />
                                    }
                                    label={item.sub_name}
                                  />
                                ))
                              ) : (
                                <Typography>No Packs available</Typography>
                              )}
                            </FormGroup>
                        </FormControl>
                        </Box>
                    ) : (
                        <Box 
                        sx={{
                            height: '200px',
                            border: '1px solid black',
                            overflowY: 'auto',  
                            paddingRight: '8px' 
                        }}
                        >
                        <FormControl component="fieldset">
                            <FormGroup>
                            {Array.isArray(section2Items) && section2Items.length > 0 ? (
                                section2Items.map((item, index) => (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={selectedItems.includes(item.subs_id)}
                                        onChange={() => handleBouquetCheckboxChange(item.subs_id)}
                                      />
                                    }
                                    label={item.sub_name}
                                  />
                                ))
                              ) : (
                                <Typography>No Packs available</Typography>
                              )}
                            </FormGroup>
                        </FormControl>
                        </Box>
                    )}
                    </Grid>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} sx={{ minWidth: 300, 
                            height: '200px',
                            border: '1px solid black',
                            overflowY: 'auto'}}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell sx={{color: '#ff8c00'}}>SI No</TableCell>
                                <TableCell sx={{color: '#ff8c00'}}>Prod Id</TableCell>
                                <TableCell sx={{color: '#ff8c00'}}>Pack Name</TableCell>
                                <TableCell sx={{color: '#ff8c00'}}>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                 {Array.isArray(planD) && planD.length > 0 ? (
                                    planD.map((row, index) => (
                                      <TableRow key={index}>
                                          <TableCell>{index+1}</TableCell>
                                          <TableCell>{row.prod_id}</TableCell>
                                          <TableCell>{row.subs_desc}</TableCell>
                                          <TableCell>{row.subs_grnd_tot_prc}</TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4} align="center">
                                        No data available
                                      </TableCell>
                                    </TableRow>
                                  )}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                </Box>
                <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '16px 0',
                borderTop: '1px solid #ddd',
                marginTop: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  marginRight: 2,
                  color: '#ff8c00',
                  borderColor: '#ff8c00',
                  '&:hover': {
                    backgroundColor: '#ff8c00',
                    borderColor: '#ffad42',
                    color: '#fff',
                  },
                  minWidth: '100px',
                }}
                onClick={handleCancelBtn} 
              >
                Cancel
              </Button>
              
              <Button
                variant="contained"
                sx={{
                  marginRight: 2,
                  backgroundColor: '#ff8c00',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#ffad42',
                  },
                  position: 'relative', 
                  minWidth: '100px', 
                }}
                onClick={handleAddChannelBtn}
                disabled={loading} 
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                ) : (
                  'Add'
                )}
              </Button>
            </Box>

          </Paper>
        </Container>

        <Footer />
      </main>
    </div>
  );
}
