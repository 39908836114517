import React from "react";
import {
    Box,
    Typography,
  } from "@mui/material";

export default function Footer() {

const currentYear = new Date().getFullYear();
  return (
        <Box
          component="footer"
          sx={{
            py: 1,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary" align="center">
            <h4>
                Copyright &copy; {currentYear} Mobiezy.com. All rights reserved.
            </h4>
          </Typography>
        </Box>   
  );
}
