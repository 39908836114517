import axios from "axios";

let authApi = axios.create({
  baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "aMqwyQJdA1aqH5GkpG5NR78UyswcHhkEaMZCfrC8",
    "Content-Type": "application/json",
  },
});
let authApi1 = axios.create({
  baseURL: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

// let authApi2 = axios.create({
//   baseURL: "https://c2.mobiezy.in/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });


let authApi4 = axios.create({
    baseURL: "https://jzou4onnja.execute-api.us-west-2.amazonaws.com",
    headers: {
      "Content-Type": "application/json",
    },
  });
// eslint-disable-next-line import/no-anonymous-default-export
//
export default {
  login(value) {
    return authApi4.post("/PROD/h_phoneVerify", value);
  },
  getlcowallet(value) {
    return authApi1.post(
      "https://c2.mobiezy.in/api/react/lco_balance_react.php",
      value
    );
  },
  getmpayownerlist(value) {
    return authApi.post("/prod/mpayv2_getmpayownerlist", value);
  },
  stbinfo(value) {
    return authApi4.post("/PROD/h_stbInfo", value);
  },
  renewPacks(value) {
    return authApi4.post("/PROD/HOTEL_STB_RENEWPACKS", value);
  },
  addChannels(value) {
    return authApi4.post("/PROD/h_Add_Channel_prc", value);
  },
  renewReport(value) {
    return authApi4.post("/PROD/h_renewal_report_prc", value);
  },
  stbAddChannel(value) {
    return authApi4.post("/PROD/HOTEL_STB_ADD_CHANNEL", value);
  },

  bulkSTBRenew(value) {
    return authApi4.post("/PROD/hotel_bulk_stb_op", value);
  },

  get_sub_lco_report(value) {
    return authApi1.post("prod/sub_lco_get_wallet_report", value);
  },

  bulkAddModify(value) {
    return authApi4.post("/PROD/HOTEL_STB_MODIFY_RENEWPACKS", value);
  },

  cancelSTB(value) {
    return authApi4.post("/PROD/hotel_cancel_request_prc", value);
  },

};
