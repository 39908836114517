// import { useParams } from 'react-router-dom';
import React,{useState,useEffect} from 'react';
// import { useNavigate,useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import Sidebar from "../sidebar/Sidebar";
// import Navbar from "../sidebar/Navbar";
import Navbar from "../sidebar/SideNavbar";
import {
  Box,
  CssBaseline,
  Container,Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, Button,CircularProgress,IconButton
} from "@mui/material";
import Footer from "../sidebar/Footer";
import AuthApi4 from "../services/authApi";
import Swal from 'sweetalert2';
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';



export default function RenewSTB(){
  const location = useLocation();
  const { stbNumber } = location.state || {};
  const [stbData, setStbData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [stbId, setStbId] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    stbRenew();
  },[]);

  const stbRenew = async(event) =>{
    let data = {
      records: [
        {
          operator_id: parseInt(localStorage.getItem("ownerId")),
          CUST_ID:localStorage.getItem("payment_cust_num"),
          stb_number:stbNumber,
          type:"RENEW"
        },
      ],
    };
    console.log(data);
    await AuthApi4.stbinfo(data)
      .then((response) => {
        console.log(response);
        const stbNewArry=response.data.stbdetails;
        setStbData(response.data.stbdetails);
        const total = (Array.isArray(stbNewArry) ? stbNewArry : []).reduce((acc, item) => acc + parseFloat(item.sub_total || 0), 0);
        setAmount(total);
        setStbId(response.data.stbdetails[0].STB_ID);
      })
      .catch((e) => {
        console.log("STB INFO Error");

        console.log(e);
      });
  }

  const handleRenew = async(event) =>{
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to renew this pack?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, renew it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        popup: 'small-swal-popup', 
      },
    });

    if (result.isConfirmed) {
      if (amount <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Amount',
          text: 'The total amount must be greater than zero to renew the pack.',
        });
        return; 
      }

      setLoading(true); 

      let data = {
            operator_id: parseInt(localStorage.getItem("ownerId")),
            CUST_ID: localStorage.getItem("payment_cust_num"),
            stb_id:stbId,
            type: "renew"
      };

      console.log(data);

      await AuthApi4.renewPacks(data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data.p_out_mssg_flg === 'S') {
          Swal.fire({
            icon: 'success',
            title: 'Pack Renewed Successfully',
            // text: 'The total amount must be greater than zero to renew the pack.',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/subscriptionM");
            }
          });
          return; 
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Renewal Failed',
            text: response.data.p_out_mssg || 'An error occurred while renewing the pack.',
          });
          return; 
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Renew API Error:", e);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong while renewing the pack. Please try again later.',
        });
      });
    }
  }

  const handleBackClick = () => {
    navigate("/subscriptionM");
  };


  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  }).format(amount);

  return (
    <div style={{ display: "flex", background: "#F5F5F5"}}>
        <CssBaseline />
        <Navbar />
        {/* <Sidebar /> */}
        <main style={{ flexGrow: 1, padding: '5px', minHeight: '100vh'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
          <Typography variant="h5" component="h2">
            Renew Packs
          </Typography>
          <IconButton onClick={handleBackClick} 
               sx={{
                marginRight: 2,
                backgroundColor: '#ff8c00',
                fontSize: '0.875rem',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#ffad42',
                },
              }}>
            <ArrowBackIcon sx={{ fontSize: '1rem', marginRight: '4px' }}/>Back
          </IconButton>
        </Box>
        <Container
            sx={{
              display: 'flex',
              
              alignItems: 'center',
              flexDirection: 'column',
              minHeight: 'calc(100vh - 64px)', 
              margin: 'auto', 
              maxWidth: 'lg', 
              height: '100vh',
            }}
          >
            <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
              Active Existing Packs
            </Typography>
            <Box
              sx={{
                width: '100%',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                // marginTop: 0
              }}
            >

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{color: '#ff8c00'}}>Pack Name</TableCell>
                    <TableCell sx={{color: '#ff8c00'}}>Pack Type</TableCell>
                    <TableCell sx={{color: '#ff8c00'}}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(stbData) && stbData.length > 0 ? (
                    stbData.map((pack, index) => (
                      <TableRow key={index}>
                        <TableCell>{pack.subs_desc}</TableCell>
                        <TableCell>{pack.pack_type}</TableCell>
                        <TableCell>{pack.sub_total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
            </Box>
            <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 0',
              borderTop: '1px solid #ddd',
              marginTop: 2,
            }}
          >
            <Typography variant="h6" component="div" sx={{ paddingLeft: 2 }}>
              Total Amount (Incl GST):{" "}
              <span style={{ color: "red" }}>
                {formattedAmount} /-
              </span>
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginRight: 2,
                backgroundColor: '#ff8c00',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#ffad42',
                },
                position: 'relative', // Ensures loader positions within button
                minWidth: '100px', // Adds a minimum width to the button
              }}
              onClick={handleRenew}
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Renew'
              )}
            </Button>
          </Box>
          </Container>
          <Footer />
        </main>
      </div>
  );
};
              